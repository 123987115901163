import React from 'react';
import { Box, Typography, List, ListItem } from '@mui/material';

const DeliveryDetails = () => {
    return (
        <Box sx={{ padding: 3, backgroundColor: '#f9f9f9', borderRadius: 2 }}>
            {/* Title */}
            <Typography variant="h5" fontWeight="bold" gutterBottom>
                Інформація про доставку
            </Typography>

            {/* Main Information */}
            <Typography variant="body1" paragraph>
                Наша служба доставки гарантує, що ваш продукт буде доставлений безпечно та вчасно.
                Ми пропонуємо кілька варіантів доставки, щоб ви могли обрати той, який найкраще
                відповідає вашим потребам
            </Typography>

            {/* Delivery Options */}
            <Typography variant="h6" fontWeight="bold" gutterBottom>
                Опції доставки:
            </Typography>
            <List>
                <ListItem>- Стандартна доставка курʼєром: 3-5 робочих днів</ListItem>
                <ListItem>- Експрес-доставка: 1-2 робочих дні</ListItem>
                <ListItem>- Самовивіз зі з нашого складу за адресою м. Львів вул. Городоцька 132, корпус А</ListItem>
            </List>

            {/* Additional Notes */}
            <Typography variant="body1" paragraph sx={{ marginTop: 2 }}>
                Усі варіанти доставки включають можливість відстеження в реальному часі, щоб ви могли
                контролювати свою посилку на кожному етапі
            </Typography>

            <Typography variant="body1" paragraph>
                Для міжнародної доставки, приблизний час очікування 7-14 робочих днів. Можуть бути
                застосовані митні збори залежно від вашої країни
            </Typography>
        </Box>
    );
};

export default DeliveryDetails;
