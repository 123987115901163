import React, {useCallback, useState} from "react";
import {ResizableBox} from "react-resizable";
import {Typewriter} from "react-simple-typewriter"; // Import Typewriter
import "react-resizable/css/styles.css";
import "../chatbot/ChatBotWeb.css";
import {debounce} from "@mui/material";
import SendIcon from "@mui/icons-material/Send";
import {Divider, IconButton} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";


const ChatBotWeb = ({
                        historyMessages,
                        currentMessages,
                        input,
                        setInput,
                        handleSendMessage,
                        chatWindowRef,
                        onClose,
                        isLoading, // Typing indicator state
                    }) => {

    const [dimensions, setDimensions] = useState({ width: 300, height: 500 });

    const handleResize = useCallback(
        debounce((data) => {
            setDimensions({ width: data.size.width, height: data.size.height });
        }, 100),
        []
    );

    return (
        <ResizableBox
            width={300}
            height={500}
            minConstraints={[200, 300]}
            maxConstraints={[900, 700]}
            resizeHandles={["nw"]}
            className="chatbot-chatbox"
            onResize={(e, data) => handleResize(data)}
            style={{ overflow: "hidden" }} // Ensure no overflow conflicts

            axis={"both"}
        >
            <div className="chatbot-header">
                <IconButton onClick={onClose} aria-label="close" size="small">
                    <CloseIcon/>
                </IconButton>
                <span className="chatbot-bot-name">T-market Bot</span>
                <span>Online</span>
            </div>

            <div className="chatbot-chat-window" ref={chatWindowRef}>
                <div className="chatbot-messages-wrapper">
                    {/* Render history messages */}
                    {historyMessages.map((message, index) => (
                        <div
                            key={`history-${index}`}
                            className={`chatbot-message ${message.sender === "bot" ? "chatbot-bot-message" : "chatbot-user-message"}`}
                        >
                            {message.text}
                            {message.productMessages &&  <Divider sx={{ marginY: 2 }} />}
                            {message.productMessages && <div>{message.productMessages}</div>}
                        </div>
                    ))}

                    {/* Separator */}
                    {historyMessages.length > 0 && (
                        <div className="chatbot-separator">
                            <span>--- Нові повідомлення ---</span>
                        </div>
                    )}

                    {/* Render current messages */}
                    {currentMessages.map((message, index) => (
                        <div
                            key={`current-${index}`}
                            className={`chatbot-message ${message.sender === "bot" ? "chatbot-bot-message" : "chatbot-user-message"}`}
                        >
                            {message.text}

                            {message.productMessages &&  <Divider sx={{ marginY: 2 }} />}
                            {message.productMessages && <div>{message.productMessages}</div>}
                        </div>
                    ))}

                    {/* Typing Indicator */}
                    {isLoading && (
                        <div className="chatbot-typing-indicator">
                            <Typewriter
                                words={["Пишу пишу..."]}
                                loop={true}
                                cursor
                                cursorStyle="|"
                                typeSpeed={80} // Speed of typing
                                deleteSpeed={50} // Speed of deleting
                                delaySpeed={1000} // Delay before looping
                            />
                        </div>
                    )}
                </div>
            </div>
            <div className="chatbot-chat-input"
                 style={{display: "flex", gap: "8px", alignItems: "center", padding: "8px"}}>
                <textarea
                    value={input}
                    onChange={(e) => setInput(e.target.value)}
                    onKeyDown={(e) => {
                        if (e.key === "Enter" && !e.shiftKey) {
                            e.preventDefault();
                            handleSendMessage();
                        }
                    }}
                    placeholder="Впишіть, будь ласка, ваше запитання:"
                    style={{
                        flexGrow: 1,
                        resize: "none", // Prevent resizing by the user
                        padding: "10px",
                        borderRadius: "5px",
                        border: "1px solid #ccc",
                        fontSize: "14px",
                        lineHeight: "1.5",
                        minHeight: "50px",
                        maxHeight: "100px",
                        overflowY: "auto",
                    }}
                />
                <IconButton
                    color="primary"
                    onClick={handleSendMessage}
                    aria-label="send"
                    sx={{
                        backgroundColor: "#5C4033",
                        color: "#fff",
                        "&:hover": {
                            backgroundColor: "#4b3227",
                        },
                    }}
                >
                    <SendIcon/>
                </IconButton>
            </div>
        </ResizableBox>
    );
};

export default ChatBotWeb;
