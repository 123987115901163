import { Button, AppBar, Toolbar, Box, Typography, IconButton, Menu, MenuItem, Drawer, List, ListItem, ListItemText } from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import FavoriteBorderIcon from "@mui/icons-material/FavoriteBorder";
import PersonOutlineIcon from "@mui/icons-material/PersonOutline";
import ShoppingCartIcon from "@mui/icons-material/ShoppingCart";
import { useState } from "react";
import { useNotification } from "../../../contexts/NotificationContext";


const Header = () => {
    const [menuAnchor, setMenuAnchor] = useState(null);
    const [mobileMenuOpen, setMobileMenuOpen] = useState(false);
    const { showNotification } = useNotification();

    const handleMenuClick = (event) => {
        setMenuAnchor(event.currentTarget);
    };

    const handleMenuClose = () => {
        setMenuAnchor(null);
    };

    const toggleMobileMenu = () => {
        setMobileMenuOpen(!mobileMenuOpen);
    };

    return (
        <AppBar
            position="absolute"
            color="transparent"
            elevation={0}
            sx={{
                backgroundColor: "rgba(0, 0, 0, 0.5)", // Напівпрозорий фон
                padding: 1,
            }}
        >
            <Toolbar sx={{ justifyContent: "space-between", alignItems: "center" }}>
                {/* Логотип та меню */}
                <Box sx={{ display: "flex", alignItems: "center", gap: 2 }}>
                    <IconButton
                        edge="start"
                        color="inherit"
                        aria-label="menu"
                        onClick={toggleMobileMenu}
                        sx={{
                            display: { xs: "flex", md: "none" }, // Показувати лише на мобільних
                            color: "#fff",
                        }}
                    >
                    </IconButton>
                    <Typography
                        variant="h5"
                        sx={{
                            color: "#fff",
                            fontWeight: "bold",
                            textTransform: "uppercase",
                        }}
                    >
                        Hombel
                    </Typography>
                </Box>

                {/* Навігація для ПК */}
                <Box sx={{ display: { xs: "none", md: "flex" }, gap: 2 }}>
                    <Button
                        onClick={() => showNotification("Увага! Це лише демонстраційний сайт. Перейдіть до списку товарів та спробуйте функцію AI-консультанта! 😊")}
                        color="inherit" sx={{ color: "#fff", fontSize: "16px" }}>Контакти
                    </Button>
                    <Button
                        onClick={() => showNotification("Увага! Це лише демонстраційний сайт. Перейдіть до списку товарів та спробуйте функцію AI-консультанта! 😊")}
                        color="inherit" sx={{ color: "#fff", fontSize: "16px" }}>Знайти магазин
                    </Button>
                    <Button
                        onClick={() => showNotification("Увага! Це лише демонстраційний сайт. Перейдіть до списку товарів та спробуйте функцію AI-консультанта! 😊")}
                        color="inherit" sx={{ color: "#fff", fontSize: "16px" }} startIcon={<FavoriteBorderIcon />}>Улюблене
                    </Button>
                    <Button
                        onClick={() => showNotification("Увага! Це лише демонстраційний сайт. Перейдіть до списку товарів та спробуйте функцію AI-консультанта! 😊")}
                        color="inherit" sx={{ color: "#fff", fontSize: "16px" }} startIcon={<PersonOutlineIcon />}>Увійти
                    </Button>
                    <Button
                        onClick={() => showNotification("Увага! Це лише демонстраційний сайт. Перейдіть до списку товарів та спробуйте функцію AI-консультанта! 😊")}
                        variant="contained"
                        color="primary"
                        startIcon={<ShoppingCartIcon />}
                        sx={{
                            borderRadius: 2,
                            backgroundColor: "#d4a373",
                            color: "#fff",
                            paddingX: 2,
                            fontSize: "16px",
                            "&:hover": { backgroundColor: "#b8845e" },
                        }}
                    >
                        Кошик (1)
                    </Button>
                </Box>
            </Toolbar>

            {/* Мобільне меню */}
            <Drawer
                anchor="left"
                open={mobileMenuOpen}
                onClose={toggleMobileMenu}
                sx={{
                    display: { xs: "block", md: "none" }, // Лише для мобільних
                }}
            >
                <List>
                    {["Контакти", "Знайти магазин", "Улюблене", "Увійти"].map((text) => (
                        <ListItem button key={text} onClick={() => showNotification("Дія демо-додатку")}>
                            <ListItemText primary={text} />
                        </ListItem>
                    ))}
                </List>
            </Drawer>
        </AppBar>
    );
};

export default Header;
