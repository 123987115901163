const products = [
        {
            "id": "66fda8a430e14968f3778e1a",
            "title": "Диван Soho",
            "price": "37000 ₴",
            "images": [
                "/images/product1/img.png",
                "/images/product1/img_1.png",
                "/images/product1/img_2.png",
                "/images/product1/img_3.png",
                "/images/product1/img_4.png"
            ],
            "description_short": "Елегантний та сучасний диван Soho – гармонія стилю та комфорту для вашого дому.",
            "description": {
                "text": "Диван Soho – це втілення сучасної елегантності та справжньої насолоди від відпочинку. Його витончені лінії, стильний дизайн і велюрова оббивка створюють атмосферу гармонії та затишку в будь-якому інтер’єрі. Завдяки високоякісним матеріалам і продуманій конструкції, Soho поєднує комфорт і практичність. Це не просто меблі, це справжній акцент вашої вітальні, який захоплює з першого погляду.",
                "characteristics": {
                    "Матеріал": "Міцна деревина (сосна) та ДСП; велюрова оббивка з гідрофобним покриттям.",
                    "Оббивка": "Доступна у 8 стильних кольорах для будь-якого інтер’єру.",
                    "Контейнер для білизни": "Так, просторий та зручний для зберігання.",
                    "Гарантія": "2 роки"
                },
                "dimensions": {
                    "Висота": "88 см",
                    "Ширина": "286 см",
                    "Глибина": "177 см",
                    "Висота ніжок": "3.5 см",
                    "Висота сидіння": "43.5 см",
                    "Ширина сидіння": "97.5 см, оттоманка: 165 см",
                    "Глибина сидіння": "58 см, оттоманка: 132 см (з подушками)",
                    "Спальне місце": "140 х 215 см"
                },
                "features": [
                    "Механізм трансформації дозволяє легко створити зручне спальне місце.",
                    "Велюрова оббивка з гідрофобним покриттям: м'яка, стійка до плям і проста у догляді.",
                    "Контейнер для білизни забезпечує додатковий простір для зберігання.",
                    "Сучасний та стильний дизайн, який гармонійно доповнить ваш інтер'єр."
                ],
                "specification": {
                    "Тип продукту": "Модульний диван",
                    "Матеріал": "Деревина, ДСП, велюрова тканина з гідрофобним покриттям",
                    "Макс. вантажопідйомність": "300 кг",
                    "Гарантія": "2 роки"
                }
            },

            opinions: [
                {
                    avatar: 'https://www.google.com/imgres?q=avatar%20image%20default&imgurl=https%3A%2F%2Fcdn.vectorstock.com%2Fi%2F1000v%2F92%2F16%2Fdefault-profile-picture-avatar-user-icon-vector-46389216.jpg&imgrefurl=https%3A%2F%2Fwww.vectorstock.com%2Froyalty-free-vector%2Fdefault-profile-picture-avatar-user-icon-vector-46389216&docid=NW9v-z9YqIHMTM&tbnid=r79fk8p230zkFM&vet=12ahUKEwjIyPCE7KWKAxUGPRAIHZE9LjAQM3oECFIQAA..i&w=1000&h=1080&hcb=2&ved=2ahUKEwjIyPCE7KWKAxUGPRAIHZE9LjAQM3oECFIQAA',
                    name: 'Алекса',
                    rating: 5,
                    comment: 'Супер!',
                    date: '12 лютого 2024',
                    advantages: 'Висока якість та зручність',
                    defects: 'Немає',
                },
                {
                    avatar: 'https://www.google.com/imgres?q=avatar%20image%20default&imgurl=https%3A%2F%2Fcdn.vectorstock.com%2Fi%2F1000v%2F92%2F16%2Fdefault-profile-picture-avatar-user-icon-vector-46389216.jpg&imgrefurl=https%3A%2F%2Fwww.vectorstock.com%2Froyalty-free-vector%2Fdefault-profile-picture-avatar-user-icon-vector-46389216&docid=NW9v-z9YqIHMTM&tbnid=r79fk8p230zkFM&vet=12ahUKEwjIyPCE7KWKAxUGPRAIHZE9LjAQM3oECFIQAA..i&w=1000&h=1080&hcb=2&ved=2ahUKEwjIyPCE7KWKAxUGPRAIHZE9LjAQM3oECFIQAA',
                    name: 'Саймон',
                    rating: 3,
                    comment:
                        ' Диван виглядає стильно, але матеріал потребує ретельного догляду. Зручно сидіти, але трохи важкий для переміщення.',
                    date: '12 лютого 2024',
                    advantages: 'Елегантний вигляд та комфорт',
                    defects:
                        '- Матеріал легко забруднюється, а диван важко переміщати.',
                },
                {
                    avatar: 'https://www.google.com/imgres?q=avatar%20image%20default&imgurl=https%3A%2F%2Fcdn.vectorstock.com%2Fi%2F1000v%2F92%2F16%2Fdefault-profile-picture-avatar-user-icon-vector-46389216.jpg&imgrefurl=https%3A%2F%2Fwww.vectorstock.com%2Froyalty-free-vector%2Fdefault-profile-picture-avatar-user-icon-vector-46389216&docid=NW9v-z9YqIHMTM&tbnid=r79fk8p230zkFM&vet=12ahUKEwjIyPCE7KWKAxUGPRAIHZE9LjAQM3oECFIQAA..i&w=1000&h=1080&hcb=2&ved=2ahUKEwjIyPCE7KWKAxUGPRAIHZE9LjAQM3oECFIQAA',
                    name: 'Олег Дякович',
                    rating: 5,
                    comment: 'Загалом, дуже задоволений покупкою. Все відповідає опису.',
                    date: '27 лютого 2023',
                    advantages: 'Гарна якість матеріалів і сучасний дизайн',
                    defects: null,
                },
            ]
        },
        {
            id: "66fda81030e14968f3778b86",
            title:
                "Керамічна раковина BlackStone by LuxCraft",
            price:
                "7200 ₴",
            images:
                [
                    "/images/product2/img.png",
                    "/images/product2/img_1.png",
                    "/images/product2/img_2.png",
                ],
            description_short:
                "Елегантний чорний умивальник для сучасних інтер'єрів.",
            description:
                {
                    text: "Чорна керамічна раковина має сучасний вигляд, що підійде до будь-якої ванної кімнати. Завдяки стійкості до вологи вона зберігає свою привабливість навіть за тривалого використання.",
                    characteristics:
                        {
                            "Матеріал":
                                "Кераміка, чорне покриття",
                            "Тип монтажу":
                                "Вбудований",
                            "Стійкість до води":
                                "Так, стійкий до води та вологи",
                            "Форма":
                                "Квадратний",
                            "Доступні покриття":
                                ["Чорний глянець", "Чорний матовий"]
                        }
                    ,
                    dimensions: {
                        "Довжина":
                            "60 см",
                        "Ширина":
                            "45 см",
                        "Висота":
                            "15 см"
                    }
                    ,
                    features: [
                        "Легко очищується від забруднень",
                        "Зручне для монтажу в будь-якій ванній кімнаті",
                        "Чорне покриття, яке додає елегантності інтер'єру",
                        "Стійкий до утворення плям і водяних слідів"
                    ],
                    specification:
                        {
                            "Тип продукту":
                                "Раковина",
                            "Матеріал":
                                "Кераміка",
                            "Тип монтажу":
                                "Вбудований",
                            "Стійкість до води":
                                "Так",
                            "Гарантія":
                                "5 років"
                        }
                }
            ,
            opinions: [
                {
                    avatar: 'https://www.google.com/imgres?q=avatar%20image%20default&imgurl=https%3A%2F%2Fcdn.vectorstock.com%2Fi%2F1000v%2F92%2F16%2Fdefault-profile-picture-avatar-user-icon-vector-46389216.jpg&imgrefurl=https%3A%2F%2Fwww.vectorstock.com%2Froyalty-free-vector%2Fdefault-profile-picture-avatar-user-icon-vector-46389216&docid=NW9v-z9YqIHMTM&tbnid=r79fk8p230zkFM&vet=12ahUKEwjIyPCE7KWKAxUGPRAIHZE9LjAQM3oECFIQAA..i&w=1000&h=1080&hcb=2&ved=2ahUKEwjIyPCE7KWKAxUGPRAIHZE9LjAQM3oECFIQAA',
                    name: 'Олександра Доскач',
                    rating: 5,
                    comment: 'Чудова раковина! Дуже стильна та якісна.',
                    date: '12 лютого 2024',
                    advantages: 'Елегантний дизайн і стійкість до вологи',
                    defects: null,
                },
                {
                    avatar: 'https://www.google.com/imgres?q=avatar%20image%20default&imgurl=https%3A%2F%2Fcdn.vectorstock.com%2Fi%2F1000v%2F92%2F16%2Fdefault-profile-picture-avatar-user-icon-vector-46389216.jpg&imgrefurl=https%3A%2F%2Fwww.vectorstock.com%2Froyalty-free-vector%2Fdefault-profile-picture-avatar-user-icon-vector-46389216&docid=NW9v-z9YqIHMTM&tbnid=r79fk8p230zkFM&vet=12ahUKEwjIyPCE7KWKAxUGPRAIHZE9LjAQM3oECFIQAA..i&w=1000&h=1080&hcb=2&ved=2ahUKEwjIyPCE7KWKAxUGPRAIHZE9LjAQM3oECFIQAA,',
                    name: 'Andrii',
                    rating: 3,
                    comment:
                        '- Раковина виглядає стильно, але потребує ретельного догляду, щоб уникнути плям. Проте, вона добре вписується в інтер’єр.',
                    date: '12 лютого 2024',
                    advantages: 'Красивий вигляд і сучасний стиль',
                    defects:
                        '- Поверхня може залишати сліди після води, потребує частого протирання.',
                },
                {
                    avatar: 'https://www.google.com/imgres?q=avatar%20image%20default&imgurl=https%3A%2F%2Fcdn.vectorstock.com%2Fi%2F1000v%2F92%2F16%2Fdefault-profile-picture-avatar-user-icon-vector-46389216.jpg&imgrefurl=https%3A%2F%2Fwww.vectorstock.com%2Froyalty-free-vector%2Fdefault-profile-picture-avatar-user-icon-vector-46389216&docid=NW9v-z9YqIHMTM&tbnid=r79fk8p230zkFM&vet=12ahUKEwjIyPCE7KWKAxUGPRAIHZE9LjAQM3oECFIQAA..i&w=1000&h=1080&hcb=2&ved=2ahUKEwjIyPCE7KWKAxUGPRAIHZE9LjAQM3oECFIQAA',
                    name: 'Максим Третяк',
                    rating: 5,
                    comment: 'Дуже задоволений покупкою. Раковина повністю відповідає опису.',
                    date: '27 лютого 2023',
                    advantages: 'Гарна якість матеріалів і зручність встановлення',
                    defects: null,
                },
            ]

        }
        ,
        {
            id: "66fda81030e14968f3778b87",
            title:
                "Люстра Mariposa",
            price:
                "19000 ₴",
            images:
                [
                    "/images/product3/img.png",
                    "/images/product3/img_1.png",
                    "/images/product3/img_2.png",
                ],
            description_short:
                "Люстра Mariposa – створення розкішної атмосфери у вашому інтер'єрі",
            description:
                {
                    text: "Люстра Mariposa створює неперевершену атмосферу розкоші завдяки своїм хрустальним елементам і енергоефективним LED лампам. Вона підходить для великих приміщень і додає класичного шарму інтер'єру.",
                    characteristics:
                        {
                            "Матеріал":
                                "Метал, скло, хрусталь",
                            "Колір":
                                "Прозорий, золотистий",
                            "Джерело світла":
                                "LED лампи (в комплекті)",
                            "Стиль":
                                "Класичний, розкішний",
                            "Регулювання яскравості":
                                "Так, з можливістю регулювання яскравості"
                        }
                    ,
                    dimensions: {
                        "Висота":
                            "120 см",
                        "Діаметр":
                            "80 см"
                    }
                    ,
                    features: [
                        "Розкішний вигляд з хрустальними елементами",
                        "LED освітлення, енергоефективне",
                        "Регулювання яскравості для створення комфортної атмосфери",
                        "Легкий монтаж, відповідні кріплення в комплекті"
                    ],
                    specification:
                        {
                            "Тип продукту":
                                "Люстра",
                            "Матеріал":
                                "Метал, скло, хрусталь",
                            "Джерело світла":
                                "LED лампи",
                            "Стиль":
                                "Класичний",
                            "Регулювання яскравості":
                                "Так",
                            "Гарантія":
                                "3 роки"
                        }
                }
            ,
            opinions: [
                {
                    avatar: 'https://www.google.com/imgres?q=avatar%20image%20default&imgurl=https%3A%2F%2Fcdn.vectorstock.com%2Fi%2F1000v%2F92%2F16%2Fdefault-profile-picture-avatar-user-icon-vector-46389216.jpg&imgrefurl=https%3A%2F%2Fwww.vectorstock.com%2Froyalty-free-vector%2Fdefault-profile-picture-avatar-user-icon-vector-46389216&docid=NW9v-z9YqIHMTM&tbnid=r79fk8p230zkFM&vet=12ahUKEwjIyPCE7KWKAxUGPRAIHZE9LjAQM3oECFIQAA..i&w=1000&h=1080&hcb=2&ved=2ahUKEwjIyPCE7KWKAxUGPRAIHZE9LjAQM3oECFIQAA',
                    name: 'Олена Марченко',
                    rating: 5,
                    comment: 'Чудова люстра! Виглядає дуже розкішно.',
                    date: '12 лютого 2024',
                    advantages: 'Висока якість і елегантний дизайн',
                    defects: 'Немає',
                },
                {
                    avatar: 'https://www.google.com/imgres?q=avatar%20image%20default&imgurl=https%3A%2F%2Fcdn.vectorstock.com%2Fi%2F1000v%2F92%2F16%2Fdefault-profile-picture-avatar-user-icon-vector-46389216.jpg&imgrefurl=https%3A%2F%2Fwww.vectorstock.com%2Froyalty-free-vector%2Fdefault-profile-picture-avatar-user-icon-vector-46389216&docid=NW9v-z9YqIHMTM&tbnid=r79fk8p230zkFM&vet=12ahUKEwjIyPCE7KWKAxUGPRAIHZE9LjAQM3oECFIQAA..i&w=1000&h=1080&hcb=2&ved=2ahUKEwjIyPCE7KWKAxUGPRAIHZE9LjAQM3oECFIQAA',
                    name: 'Ігор Ковальчук',
                    rating: 3,
                    comment:
                        '- Люстра виглядає красиво, але складання зайняло більше часу, ніж очікувалося. Проте результат вартує зусиль.',
                    date: '12 лютого 2024',
                    advantages: 'Стильний вигляд і освітлення',
                    defects:
                        '- Монтаж трохи складний, потрібно бути обережним з хрусталем.',
                },
                {
                    avatar: 'https://www.google.com/imgres?q=avatar%20image%20default&imgurl=https%3A%2F%2Fcdn.vectorstock.com%2Fi%2F1000v%2F92%2F16%2Fdefault-profile-picture-avatar-user-icon-vector-46389216.jpg&imgrefurl=https%3A%2F%2Fwww.vectorstock.com%2Froyalty-free-vector%2Fdefault-profile-picture-avatar-user-icon-vector-46389216&docid=NW9v-z9YqIHMTM&tbnid=r79fk8p230zkFM&vet=12ahUKEwjIyPCE7KWKAxUGPRAIHZE9LjAQM3oECFIQAA..i&w=1000&h=1080&hcb=2&ved=2ahUKEwjIyPCE7KWKAxUGPRAIHZE9LjAQM3oECFIQAA',
                    name: 'Дмитро Тарасов',
                    rating: 5,
                    comment: 'Я в захваті від цієї люстри! Ідеально підходить для вітальні.',
                    date: '27 лютого 2023',
                    advantages: 'Простий монтаж і розкішний вигляд',
                    defects: null,
                },
            ]

        }
        ,

////////////////////////////
////////////////////////////
////////////////////////////
////////////////////////////
////////////////////////////
////////////////////////////
        {
            id: "66fda81030e14968f3778b12",
            title:
                "Крісло Moooi Knitty Citadel",
            price:
                "14000 ₴",
            images:
                [
                    "/images/product4/img_1.png",
                    "/images/product4/img_2.png",
                    "/images/product4/img_3.png",
                ],
            description_short:
                "Cтильне та зручне крісло для будь-якого сучасного інтер'єру.",
            description:
                {
                    text: "Крісло Moooi Knitty Citadel поєднує стильний дизайн та неймовірний комфорт. Воно виконане з високоякісних матеріалів і стане чудовим доповненням до сучасного інтер'єру, створюючи атмосферу затишку та розкоші.",
                    characteristics:
                        {
                            "Матеріал":
                                "Метал, тканина",
                            "Колір":
                                "Сірий",
                            "Дизайн":
                                "Сучасний, стильний",
                            "Особливості":
                                "Зручне сидіння, міцна конструкція",
                            "Розмір":
                                "Підходить для будь-якої кімнати"
                        }
                    ,
                    dimensions: {
                        "Висота":
                            "90 см",
                        "Ширина":
                            "80 см",
                        "Глибина":
                            "70 см"
                    }
                    ,
                    features: [
                        "Ідеальне поєднання комфорту та елегантності",
                        "Зроблене з міцних та довговічних матеріалів",
                        "Підходить для сучасних інтер'єрів",
                        "Легкий догляд за матеріалами"
                    ],
                    specification:
                        {
                            "Тип продукту":
                                "Крісло",
                            "Матеріал":
                                "Метал, тканина",
                            "Колір":
                                "Сірий",
                            "Дизайн":
                                "Сучасний",
                            "Гарантія":
                                "2 роки"
                        }
                }
            ,
            opinions: [
                {
                    avatar: 'https://www.google.com/imgres?q=avatar%20image%20default&imgurl=https%3A%2F%2Fcdn.vectorstock.com%2Fi%2F1000v%2F92%2F16%2Fdefault-profile-picture-avatar-user-icon-vector-46389216.jpg&imgrefurl=https%3A%2F%2Fwww.vectorstock.com%2Froyalty-free-vector%2Fdefault-profile-picture-avatar-user-icon-vector-46389216&docid=NW9v-z9YqIHMTM&tbnid=r79fk8p230zkFM&vet=12ahUKEwjIyPCE7KWKAxUGPRAIHZE9LjAQM3oECFIQAA..i&w=1000&h=1080&hcb=2&ved=2ahUKEwjIyPCE7KWKAxUGPRAIHZE9LjAQM3oECFIQAA',
                    name: 'Олена Марченко',
                    rating: 5,
                    comment: 'Чудове крісло! Дуже зручне та стильне.',
                    date: '15 березня 2024',
                    advantages: 'Висока якість матеріалів та комфорт',
                    defects: 'Немає',
                },
                {
                    avatar: 'https://www.google.com/imgres?q=avatar%20image%20default&imgurl=https%3A%2F%2Fcdn.vectorstock.com%2Fi%2F1000v%2F92%2F16%2Fdefault-profile-picture-avatar-user-icon-vector-46389216.jpg&imgrefurl=https%3A%2F%2Fwww.vectorstock.com%2Froyalty-free-vector%2Fdefault-profile-picture-avatar-user-icon-vector-46389216&docid=NW9v-z9YqIHMTM&tbnid=r79fk8p230zkFM&vet=12ahUKEwjIyPCE7KWKAxUGPRAIHZE9LjAQM3oECFIQAA..i&w=1000&h=1080&hcb=2&ved=2ahUKEwjIyPCE7KWKAxUGPRAIHZE9LjAQM3oECFIQAA',
                    name: 'Ігор Ковальчук',
                    rating: 4,
                    comment:
                        '- Крісло дуже зручне, але матеріал вимагає додаткового догляду, щоб уникнути плям.',
                    date: '10 квітня 2024',
                    advantages: 'Сучасний дизайн і комфортне сидіння',
                    defects:
                        '- Матеріал потребує додаткового догляду.',
                },
                {
                    avatar: 'https://www.google.com/imgres?q=avatar%20image%20default&imgurl=https%3A%2F%2Fcdn.vectorstock.com%2Fi%2F1000v%2F92%2F16%2Fdefault-profile-picture-avatar-user-icon-vector-46389216.jpg&imgrefurl=https%3A%2F%2Fwww.vectorstock.com%2Froyalty-free-vector%2Fdefault-profile-picture-avatar-user-icon-vector-46389216&docid=NW9v-z9YqIHMTM&tbnid=r79fk8p230zkFM&vet=12ahUKEwjIyPCE7KWKAxUGPRAIHZE9LjAQM3oECFIQAA..i&w=1000&h=1080&hcb=2&ved=2ahUKEwjIyPCE7KWKAxUGPRAIHZE9LjAQM3oECFIQAA',
                    name: 'Дмитро Тарасов',
                    rating: 5,
                    comment: 'Крісло перевершило всі очікування! Дуже рекомендую.',
                    date: '5 травня 2023',
                    advantages: 'Зручне сидіння та стильний вигляд',
                    defects: null,
                },
            ]
        }
        ,
        {
            id: "66fda81030e14968f3778b13",
            title:
                "Лампа Oluce Coupe 3320R",
            price:
                "4500 ₴",
            images:
                [
                    "/images/product5/img_1.png",
                    "/images/product5/img_2.png",
                    "/images/product5/img_3.png",
                ],
            description_short:
                "Стильна кімнатна лампа для сучасного освітлення вашого інтер'єру.",
            description:
                {
                    text: "Лампа Oluce Coupe 3320R – це поєднання сучасного дизайну та високої функціональності. Завдяки витонченій формі та регульованій конструкції ця лампа ідеально підходить для використання як вдома, так і в офісі. Вона створює затишну атмосферу завдяки м'якому розсіяному світлу.",
                    characteristics:
                        {
                            "Матеріал":
                                "Метал, алюміній",
                            "Колір":
                                "Чорний або білий",
                            "Джерело світла":
                                "Світлодіодна лампа (в комплекті)",
                            "Регулювання":
                                "Так, з можливістю нахилу абажура",
                            "Дизайн":
                                "Сучасний, мінімалістичний"
                        }
                    ,
                    dimensions: {
                        "Висота":
                            "160 см",
                        "Діаметр основи":
                            "40 см",
                        "Діаметр абажура":
                            "30 см"
                    }
                    ,
                    features: [
                        "Модний та сучасний дизайн",
                        "Регульований абажур для спрямованого освітлення",
                        "Висока якість матеріалів та довговічність",
                        "Підходить для використання в офісі та вдома"
                    ],
                    specification:
                        {
                            "Тип продукту":
                                "Кімнатна лампа",
                            "Матеріал":
                                "Метал, алюміній",
                            "Колір":
                                "Чорний або білий",
                            "Джерело світла":
                                "Світлодіодна лампа",
                            "Регулювання нахилу":
                                "Так",
                            "Гарантія":
                                "3 роки"
                        }
                }
            ,
            opinions: [
                {
                    avatar: 'https://www.google.com/imgres?q=avatar%20image%20default&imgurl=https%3A%2F%2Fcdn.vectorstock.com%2Fi%2F1000v%2F92%2F16%2Fdefault-profile-picture-avatar-user-icon-vector-46389216.jpg&imgrefurl=https%3A%2F%2Fwww.vectorstock.com%2Froyalty-free-vector%2Fdefault-profile-picture-avatar-user-icon-vector-46389216&docid=NW9v-z9YqIHMTM&tbnid=r79fk8p230zkFM&vet=12ahUKEwjIyPCE7KWKAxUGPRAIHZE9LjAQM3oECFIQAA..i&w=1000&h=1080&hcb=2&ved=2ahUKEwjIyPCE7KWKAxUGPRAIHZE9LjAQM3oECFIQAA',
                    name: 'Оксана Ткаченко',
                    rating: 5,
                    comment: 'Дуже стильна лампа! Ідеально вписалася в інтер’єр моєї вітальні.',
                    date: '10 березня 2024',
                    advantages: 'Висока якість матеріалів та стильний вигляд',
                    defects: 'Немає',
                },
                {
                    avatar: 'https://www.google.com/imgres?q=avatar%20image%20default&imgurl=https%3A%2F%2Fcdn.vectorstock.com%2Fi%2F1000v%2F92%2F16%2Fdefault-profile-picture-avatar-user-icon-vector-46389216.jpg&imgrefurl=https%3A%2F%2Fwww.vectorstock.com%2Froyalty-free-vector%2Fdefault-profile-picture-avatar-user-icon-vector-46389216&docid=NW9v-z9YqIHMTM&tbnid=r79fk8p230zkFM&vet=12ahUKEwjIyPCE7KWKAxUGPRAIHZE9LjAQM3oECFIQAA..i&w=1000&h=1080&hcb=2&ved=2ahUKEwjIyPCE7KWKAxUGPRAIHZE9LjAQM3oECFIQAA',
                    name: 'Андрій Петренко',
                    rating: 4,
                    comment:
                        '- Лампа чудова, але кабель міг би бути довшим. Світло м’яке і приємне.',
                    date: '25 березня 2024',
                    advantages: 'Зручна у використанні, регульований абажур',
                    defects:
                        '- Недостатньо довгий кабель.',
                },
                {
                    avatar: 'https://www.google.com/imgres?q=avatar%20image%20default&imgurl=https%3A%2F%2Fcdn.vectorstock.com%2Fi%2F1000v%2F92%2F16%2Fdefault-profile-picture-avatar-user-icon-vector-46389216.jpg&imgrefurl=https%3A%2F%2Fwww.vectorstock.com%2Froyalty-free-vector%2Fdefault-profile-picture-avatar-user-icon-vector-46389216&docid=NW9v-z9YqIHMTM&tbnid=r79fk8p230zkFM&vet=12ahUKEwjIyPCE7KWKAxUGPRAIHZE9LjAQM3oECFIQAA..i&w=1000&h=1080&hcb=2&ved=2ahUKEwjIyPCE7KWKAxUGPRAIHZE9LjAQM3oECFIQAA',
                    name: 'Світлана Бондар',
                    rating: 5,
                    comment: 'Прекрасний вибір для робочого столу! Дуже задоволена.',
                    date: '1 квітня 2023',
                    advantages: 'Якісний дизайн та практичність',
                    defects: null,
                },
            ]
        }
        ,
        {
            id: "66fda81030e14968f3778b14",
            title:
                "Кошик для білизни Vipp 441",
            price:
                "3800 ₴",
            images:
                [
                    "/images/product6/img_1.png",
                    "/images/product6/img_2.png",
                    "/images/product6/img_3.png",
                    "/images/product6/img_4.png",
                    "/images/product6/img_5.png",
                    "/images/product6/img_6.png",
                    "/images/product6/img_7.png",
                ],
            description_short:
                "Функціональний та стильний кошик для зберігання білизни.",
            description:
                {
                    text: "Кошик для білизни Vipp 441 – це ідеальне рішення для організації простору у вашому домі. Виготовлений з міцних матеріалів, цей кошик поєднує стильний дизайн і практичність. Завдяки продуманій конструкції та вентиляційним отворам, він забезпечує зручність та комфорт у використанні.",
                    characteristics:
                        {
                            "Матеріал":
                                "Сталь, пластик",
                            "Колір":
                                "Чорний або білий",
                            "Місткість":
                                "4 літри",
                            "Вентиляція":
                                "Отвори для циркуляції повітря",
                            "Дизайн":
                                "Сучасний, мінімалістичний"
                        }
                    ,
                    dimensions: {
                        "Висота":
                            "30 см",
                        "Діаметр":
                            "20 см"
                    }
                    ,
                    features: [
                        "Вентиляційні отвори для зберігання білизни",
                        "Міцні та довговічні матеріали",
                        "Сучасний дизайн, що підходить для будь-якого інтер’єру",
                        "Зручний у використанні та легкий у догляді"
                    ],
                    specification:
                        {
                            "Тип продукту":
                                "Кошик для білизни",
                            "Матеріал":
                                "Сталь, пластик",
                            "Колір":
                                "Чорний або білий",
                            "Місткість":
                                "4 літри",
                            "Гарантія":
                                "2 роки"
                        }
                }
            ,
            opinions: [
                {
                    avatar: 'https://www.google.com/imgres?q=avatar%20image%20default&imgurl=https%3A%2F%2Fcdn.vectorstock.com%2Fi%2F1000v%2F92%2F16%2Fdefault-profile-picture-avatar-user-icon-vector-46389216.jpg&imgrefurl=https%3A%2F%2Fwww.vectorstock.com%2Froyalty-free-vector%2Fdefault-profile-picture-avatar-user-icon-vector-46389216&docid=NW9v-z9YqIHMTM&tbnid=r79fk8p230zkFM&vet=12ahUKEwjIyPCE7KWKAxUGPRAIHZE9LjAQM3oECFIQAA..i&w=1000&h=1080&hcb=2&ved=2ahUKEwjIyPCE7KWKAxUGPRAIHZE9LjAQM3oECFIQAA',
                    name: 'Марія Іванченко',
                    rating: 5,
                    comment: 'Стильний кошик, дуже зручний для білизни!',
                    date: '20 березня 2024',
                    advantages: 'Гарний дизайн і вентиляційні отвори',
                    defects: 'Немає',
                },
                {
                    avatar: 'https://www.google.com/imgres?q=avatar%20image%20default&imgurl=https%3A%2F%2Fcdn.vectorstock.com%2Fi%2F1000v%2F92%2F16%2Fdefault-profile-picture-avatar-user-icon-vector-46389216.jpg&imgrefurl=https%3A%2F%2Fwww.vectorstock.com%2Froyalty-free-vector%2Fdefault-profile-picture-avatar-user-icon-vector-46389216&docid=NW9v-z9YqIHMTM&tbnid=r79fk8p230zkFM&vet=12ahUKEwjIyPCE7KWKAxUGPRAIHZE9LjAQM3oECFIQAA..i&w=1000&h=1080&hcb=2&ved=2ahUKEwjIyPCE7KWKAxUGPRAIHZE9LjAQM3oECFIQAA',
                    name: 'Олександр Кравчук',
                    rating: 4,
                    comment:
                        '- Дуже зручний кошик, але ціна трохи зависока. В іншому все чудово.',
                    date: '15 квітня 2024',
                    advantages: 'Міцні матеріали та якість виготовлення',
                    defects:
                        '- Ціна могла б бути нижчою.',
                },
                {
                    avatar: 'https://www.google.com/imgres?q=avatar%20image%20default&imgurl=https%3A%2F%2Fcdn.vectorstock.com%2Fi%2F1000v%2F92%2F16%2Fdefault-profile-picture-avatar-user-icon-vector-46389216.jpg&imgrefurl=https%3A%2F%2Fwww.vectorstock.com%2Froyalty-free-vector%2Fdefault-profile-picture-avatar-user-icon-vector-46389216&docid=NW9v-z9YqIHMTM&tbnid=r79fk8p230zkFM&vet=12ahUKEwjIyPCE7KWKAxUGPRAIHZE9LjAQM3oECFIQAA..i&w=1000&h=1080&hcb=2&ved=2ahUKEwjIyPCE7KWKAxUGPRAIHZE9LjAQM3oECFIQAA',
                    name: 'Світлана Гордій',
                    rating: 5,
                    comment: 'Ідеально підходить для зберігання білизни. Стильно та функціонально.',
                    date: '5 травня 2023',
                    advantages: 'Легкий у догляді та сучасний вигляд',
                    defects: null,
                },
            ]
        }
        ,
////////
        {
            id: "66fda8a530e14968f3778e2a",
            title:
                "Диван Lario Comfort",
            price:
                "42000 ₴",
            images:
                [
                    "/images/product7/img_1.png",
                    "/images/product7/img_2.png",
                    "/images/product7/img_3.png",
                    "/images/product7/img_4.png",
                    "/images/product7/img_5.png",
                    "/images/product7/img_6.png"
                ],
            description_short:
                "Елегантний модульний диван із DL механізмом для розкладання, ідеальний для створення комфортного відпочинку вдома.",
            description:
                {
                    "text":
                        "Диван Lario Comfort створений для поєднання естетики і зручності. Його сучасний мінімалістичний стиль доповнюється високоякісними матеріалами та модульною конструкцією, яка дозволяє підлаштовувати диван під будь-який простір і потреби. Завдяки DL механізму для розкладання, диван трансформується у зручне спальне місце розміром 200 х 220 см, що ідеально підходить для щоденного використання або розміщення гостей. М'які подушки та широка зона сидіння забезпечують максимальний комфорт для щоденного відпочинку та приємних моментів з близькими.",
                    "characteristics":
                        {
                            "Матеріал":
                                "Тканина преміум-класу, натуральне дерево, міцний метал",
                            "Колір":
                                "Сірий або бежевий — універсальні відтінки для будь-якого інтер’єру",
                            "Дизайн":
                                "Сучасний мінімалізм",
                            "Особливості":
                                "Модульна конструкція, DL механізм для розкладання, регульовані елементи, м'які знімні подушки",
                            "Розмір":
                                "Просторий, ідеальний для великих кімнат"
                        }
                    ,
                    "dimensions":
                        {
                            "Висота":
                                "85 см",
                            "Ширина":
                                "220 см",
                            "Глибина":
                                "100 см",
                            "Вага":
                                "55 кг",
                            "Спальне місце":
                                "200 х 220 см"
                        }
                    ,
                    "features":
                        [
                            "Широка зона сидіння для комфортного розміщення",
                            "DL механізм забезпечує просте та швидке розкладання",
                            "Просторе спальне місце розміром 200 х 220 см для комфортного сну",
                            "Модульний дизайн дозволяє змінювати конфігурацію",
                            "Легкий догляд за оббивкою завдяки якісним матеріалам",
                            "Ергономічна форма для довготривалого використання",
                            "Стильний вигляд, який гармонійно вписується в будь-який сучасний інтер’єр"
                        ],
                    "specification":
                        {
                            "Тип продукту":
                                "Модульний диван з механізмом трансформації",
                            "Матеріал":
                                "Тканина, дерево, метал",
                            "Колір":
                                "Сірий або бежевий",
                            "Дизайн":
                                "Сучасний мінімалізм",
                            "Механізм розкладання":
                                "DL механізм",
                            "Габарити спального місця":
                                "200 х 220 см",
                            "Гарантія":
                                "5 років — підтвердження надійності і довговічності"
                        }
                }
            ,
            opinions: [
                {
                    avatar: 'https://www.google.com/imgres?q=avatar%20image%20default&imgurl=https%3A%2F%2Fcdn.vectorstock.com%2Fi%2F1000v%2F92%2F16%2Fdefault-profile-picture-avatar-user-icon-vector-46389216.jpg&imgrefurl=https%3A%2F%2Fwww.vectorstock.com%2Froyalty-free-vector%2Fdefault-profile-picture-avatar-user-icon-vector-46389216&docid=NW9v-z9YqIHMTM&tbnid=r79fk8p230zkFM&vet=12ahUKEwjIyPCE7KWKAxUGPRAIHZE9LjAQM3oECFIQAA..i&w=1000&h=1080&hcb=2&ved=2ahUKEwjIyPCE7KWKAxUGPRAIHZE9LjAQM3oECFIQAA',
                    name: 'Олена Марченко',
                    rating: 5,
                    comment: 'Чудове крісло! Дуже зручне та стильне.',
                    date: '15 березня 2024',
                    advantages: 'Висока якість матеріалів та комфорт',
                    defects: 'Немає',
                },
                {
                    avatar: 'https://www.google.com/imgres?q=avatar%20image%20default&imgurl=https%3A%2F%2Fcdn.vectorstock.com%2Fi%2F1000v%2F92%2F16%2Fdefault-profile-picture-avatar-user-icon-vector-46389216.jpg&imgrefurl=https%3A%2F%2Fwww.vectorstock.com%2Froyalty-free-vector%2Fdefault-profile-picture-avatar-user-icon-vector-46389216&docid=NW9v-z9YqIHMTM&tbnid=r79fk8p230zkFM&vet=12ahUKEwjIyPCE7KWKAxUGPRAIHZE9LjAQM3oECFIQAA..i&w=1000&h=1080&hcb=2&ved=2ahUKEwjIyPCE7KWKAxUGPRAIHZE9LjAQM3oECFIQAA',
                    name: 'Ігор Ковальчук',
                    rating: 4,
                    comment:
                        '- Крісло дуже зручне, але матеріал вимагає додаткового догляду, щоб уникнути плям.',
                    date: '10 квітня 2024',
                    advantages: 'Сучасний дизайн і комфортне сидіння',
                    defects:
                        '- Матеріал потребує додаткового догляду.',
                },
                {
                    avatar: 'https://www.google.com/imgres?q=avatar%20image%20default&imgurl=https%3A%2F%2Fcdn.vectorstock.com%2Fi%2F1000v%2F92%2F16%2Fdefault-profile-picture-avatar-user-icon-vector-46389216.jpg&imgrefurl=https%3A%2F%2Fwww.vectorstock.com%2Froyalty-free-vector%2Fdefault-profile-picture-avatar-user-icon-vector-46389216&docid=NW9v-z9YqIHMTM&tbnid=r79fk8p230zkFM&vet=12ahUKEwjIyPCE7KWKAxUGPRAIHZE9LjAQM3oECFIQAA..i&w=1000&h=1080&hcb=2&ved=2ahUKEwjIyPCE7KWKAxUGPRAIHZE9LjAQM3oECFIQAA',
                    name: 'Дмитро Тарасов',
                    rating: 5,
                    comment: 'Крісло перевершило всі очікування! Дуже рекомендую.',
                    date: '5 травня 2023',
                    advantages: 'Зручне сидіння та стильний вигляд',
                    defects: null,
                },
            ]
        }
        ,
        {
            id: "66fda8a530e14968f3778e3a",
            title:
                "Диван Relaxio Modular",
            price:
                "39000 ₴",
            images:
                [
                    "/images/product8/img_1.png",
                    "/images/product8/img_2.png",
                    "/images/product8/img_3.png",
                    "/images/product8/img_4.png"
                ],
            description_short:
                "Сучасний модульний диван, який адаптується до ваших потреб і підходить для будь-якого інтер’єру.",
            description:
                {
                    text: "Диван Relaxio Modular дозволяє налаштовувати конфігурацію відповідно до ваших потреб. Його універсальний дизайн та матеріали преміум-якості роблять його ідеальним для будь-якого інтер’єру.",
                    characteristics:
                        {
                            "Матеріал":
                                "Екошкіра, метал",
                            "Колір":
                                "Темно-сірий, чорний",
                            "Дизайн":
                                "Сучасний, універсальний",
                            "Особливості":
                                "Модульна система, м'які спинки",
                            "Розмір":
                                "Компактний для невеликих приміщень"
                        }
                    ,
                    dimensions: {
                        "Висота":
                            "85 см",
                        "Ширина":
                            "200 см",
                        "Глибина":
                            "90 см"
                    }
                    ,
                    features: [
                        "Модульний дизайн для різних конфігурацій",
                        "Стійкий до зношування матеріал",
                        "Сучасний вигляд, який підходить для будь-якого простору",
                        "Легке очищення"
                    ],
                    specification:
                        {
                            "Тип продукту":
                                "Диван",
                            "Матеріал":
                                "Екошкіра, метал",
                            "Колір":
                                "Темно-сірий або чорний",
                            "Дизайн":
                                "Сучасний",
                            "Гарантія":
                                "3 роки"
                        }
                }
            ,
            opinions: [
                {
                    avatar: "https://www.example.com/avatar.jpg",
                    name: "Ірина Мельник",
                    rating: 5,
                    comment: "Диван дуже зручний та легкий в догляді, ідеально підходить для нашого інтер'єру.",
                    date: "7 грудня 2024",
                    advantages: "Легко чиститься, комфортний",
                    defects: "Не знайшла"
                },
                {
                    avatar: "https://www.example.com/avatar.jpg",
                    name: "Віталій Сидоренко",
                    rating: 4,
                    comment: "Добре виглядає в інтер'єрі, але хотілося б, щоб спинка була трошки вищою.",
                    date: "9 грудня 2024",
                    advantages: "Естетичний вигляд, модульність",
                    defects: "Низька спинка"
                }
            ]
        }
        ,
        {
            id: "66fda8a530e14968f3778e4a",
            title:
                "Лампа LuxSphere Aura",
            price:
                "5200 ₴",
            images:
                [
                    "/images/product9/img_1.png",
                    "/images/product9/img_2.png",
                    "/images/product9/img_3.png",
                    "/images/product9/img_4.png"
                ],
            description_short:
                "Люстра з хрустальними елементами та LED освітленням, що додає розкоші та елегантності інтер'єру.",
            description:
                {
                    text: "Лампа LuxSphere Aura ідеально підходить для створення затишної атмосфери в будь-якому приміщенні. Її компактний дизайн і м’яке світло додають тепла і комфорту.",
                    characteristics:
                        {
                            "Матеріал":
                                "Алюміній, скло",
                            "Колір":
                                "Білий, золотистий, Світловий",
                            "джерело":
                                "LED лампа (в комплекті)",
                            "Регульований":
                                "Ні",
                            "Дизайн":
                                "Мінімалістичний"
                        }
                    ,
                    dimensions: {
                        "Висота":
                            "50 см",
                        "Діаметр основи":
                            "20 см",
                        "Діаметр абажура":
                            "25 см"
                    }
                    ,
                    features: [
                        "Компактний та стильний дизайн",
                        "М'яке та рівномірне освітлення",
                        "Якісні матеріали для тривалого використання",
                        "Ідеально підходить для спальні чи вітальні"
                    ],
                    specification:
                        {
                            "Тип продукту":
                                "Кімнатна лампа",
                            "Матеріал":
                                "Алюміній, скло",
                            "Колір":
                                "Білий або золотистий",
                            "Дизайн":
                                "Мінімалістичний",
                            "Гарантія":
                                "2 роки"
                        }
                }
            ,
            opinions: [
                {
                    avatar: "https://www.example.com/avatar.jpg",
                    name: "Людмила Острівна",
                    rating: 5,
                    comment: "Додає дуже елегантний вигляд нашій вітальні.",
                    date: "20 грудня 2024",
                    advantages: "Мінімалістичний дизайн та чудове світло",
                    defects: "Немає"
                },
                {
                    avatar: "https://www.example.com/avatar.jpg",
                    name: "Сергій Дорошенко",
                    rating: 4,
                    comment: "Лампа гарна, але хотілося б, щоб регулювання яскравості було.",
                    date: "22 грудня 2024",
                    advantages: "Стильний вигляд і хороша якість",
                    defects: "Відсутність регулювання яскравості"
                }
            ]
        }


    ]
;

module.exports = products;
