import React from "react";
import {
    Box,
    Grid,
    Typography,
    ButtonBase,
    CardMedia,
} from "@mui/material";

const MidSection = () => {
    const categories = [
        { title: "Дивани", image: "./images/sofa.png" },
        { title: "Декор", image: "./images/flower.png" },
        { title: "Крісла", image: "./images/chair.png" },
    ];

    const rooms = [
        { title: "Кухня", image: "/images/KitchenRoom.png" },
        { title: "Вітальня", image: "/images/LivingRoom.png" },
    ];

    return (
        <Box sx={{ padding: { xs: 2, sm: 4 } }}>
            {/* Section 1: Shop by Categories */}
            <Box sx={{ marginBottom: 6 }}>
                <Grid container spacing={4} alignItems="center">
                    {/* Left Text Section */}
                    <Grid item xs={12} sm={3}>
                        <Box>
                            <Typography
                                variant="h2"
                                sx={{
                                    fontWeight: "bold",
                                    marginBottom: 2,
                                    fontSize: { xs: "1.5rem", sm: "2rem", md: "2.5rem" }, // Responsive font size
                                    position: "relative",
                                }}
                            >
                                Огляд по категоріях
                                <Box
                                    sx={{
                                        position: "absolute",
                                        bottom: "-5px",
                                        left: 0,
                                        width: "50px",
                                        height: "4px",
                                        backgroundColor: "#d4a373",
                                    }}
                                />
                            </Typography>
                            <Typography
                                variant="body1"
                                sx={{ color: "text.secondary", marginBottom: 4 }}
                            >
                                <ButtonBase
                                    sx={{
                                        textTransform: "none",
                                        fontWeight: "bold",
                                        fontSize: "16px",
                                        display: "flex",
                                        alignItems: "center",
                                        gap: "5px",
                                        color: "#d4a373",
                                        "&:hover": { textDecoration: "underline" },
                                    }}
                                >
                                    Всі категорії →
                                </ButtonBase>
                            </Typography>
                        </Box>
                    </Grid>

                    {/* Right Images Section */}
                    <Grid item xs={12} sm={9}>
                        <Box
                            sx={{
                                display: "flex",
                                gap: 2,
                                flexWrap: "wrap", // Wrap items for mobile
                                justifyContent: { xs: "center", sm: "space-between" },
                            }}
                        >
                            {categories.map((category, index) => (
                                <Box
                                    key={index}
                                    sx={{
                                        display: "flex",
                                        flexDirection: "column",
                                        alignItems: "center",
                                        textAlign: "center",
                                        width: { xs: "100%", sm: "45%", md: "30%" }, // Responsive width
                                    }}
                                >
                                    <Box
                                        sx={{
                                            aspectRatio: "1 / 1", // Makes the container square
                                            width: "100%",
                                            maxWidth: { xs: "200px", sm: "300px", md: "400px" }, // Scales with screen size
                                            backgroundColor: "#f9f9f9",
                                            borderRadius: 2, // Rounded corners
                                            overflow: "hidden",
                                            boxShadow: 3,
                                        }}
                                    >
                                        <img
                                            src={category.image}
                                            alt={category.title}
                                            style={{
                                                width: "100%",
                                                height: "100%",
                                                objectFit: "cover",
                                            }}
                                        />
                                    </Box>
                                    <Typography
                                        variant="body1"
                                        sx={{
                                            fontWeight: "bold",
                                            marginTop: 1,
                                            textTransform: "capitalize",
                                            fontSize: { xs: "0.9rem", sm: "1rem" }, // Responsive font size
                                        }}
                                    >
                                        {category.title}
                                    </Typography>
                                </Box>
                            ))}
                        </Box>
                    </Grid>
                </Grid>
            </Box>

            {/* Section 2: Shop by Rooms */}
            <Grid container spacing={4}>
                {rooms.map((room, index) => (
                    <Grid item xs={12} sm={6} key={index}>
                        <ButtonBase
                            sx={{
                                width: "100%",
                                height: { xs: "250px", sm: "400px", md: "600px" }, // Responsive height
                                position: "relative",
                                borderRadius: 2,
                                overflow: "hidden",
                            }}
                        >
                            <CardMedia
                                component="img"
                                image={room.image}
                                alt={room.title}
                                sx={{
                                    objectFit: "cover",
                                    width: "100%",
                                    height: "100%",
                                    filter: "brightness(0.8)",
                                }}
                            />
                            <Box
                                sx={{
                                    position: "absolute",
                                    top: "50%",
                                    left: "50%",
                                    transform: "translate(-50%, -50%)",
                                    textAlign: "center",
                                    color: "#fff",
                                }}
                            >
                                <Typography
                                    variant="h4"
                                    sx={{
                                        fontWeight: "bold",
                                        textTransform: "uppercase",
                                        marginBottom: 1,
                                        fontSize: { xs: "1.2rem", sm: "1.5rem", md: "2rem" }, // Responsive font size
                                    }}
                                >
                                    {room.title}
                                </Typography>
                                <Typography
                                    variant="body2"
                                    sx={{
                                        fontSize: { xs: "0.8rem", sm: "1rem" }, // Responsive font size
                                        fontWeight: "bold",
                                    }}
                                >
                                    Оберіть товари для кімнати
                                </Typography>
                            </Box>
                        </ButtonBase>
                    </Grid>
                ))}
            </Grid>
        </Box>
    );
};

export default MidSection;

