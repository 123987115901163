/** @format */

import axios from 'axios';

const commonHeaders = {
    'Content-Type': 'application/json',
};

export const chatbotAxios = axios.create({
    baseURL: 'https://proxy.t-market.app',
    headers: commonHeaders,
    timeout: 45000,
    timeoutErrorMessage: 'TIMEOUT',
    withCredentials: true,
})
