import React from 'react';
import { Button, Box, Typography } from '@mui/material';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';

const BuyButton = ({ price, onClick }) => {
    return (
        <Box
            sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                gap: 1,
                maxWidth: '300px',
                marginTop: 2,

            }}
        >
            <Typography
                variant="h6"
                color="primary"
                sx={{
                    fontWeight: 'bold',
                }}
            >
                {`Ціна: ${price}`}
            </Typography>
            <Button
                variant="contained"
                color="primary"
                size="large"
                onClick={onClick}
                startIcon={<ShoppingCartIcon />}
                sx={{
                    fontSize: '1.2rem',
                    padding: '12px 24px',
                    borderRadius: 3,
                    boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.1)',
                    transition: 'transform 0.2s ease',
                    '&:hover': {
                        backgroundColor: '#004ba0',
                        transform: 'scale(1.05)',
                    },
                }}
            >
                Додати до Кошика
            </Button>
        </Box>
    );
};

export default BuyButton;
