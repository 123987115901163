import React from "react";
import { Box, Card, CardContent, Typography, Button } from "@mui/material";

const ChatProductInfo = ({ product, handleProductClick }) => {
    return (
        <Box
            sx={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "space-between",
                height: "350px", // Set a fixed height for the card
                width: "100%", // Ensure it matches the parent container
                boxShadow: 3,
                borderRadius: 2,
            }}
        >
            <Card
                sx={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "space-between",
                    height: "100%",
                    width: "100%", // Ensure it matches the parent container
                    boxShadow: 3,
                    borderRadius: 2,
                }}
            >
                {/* Image */}
                <Box
                    sx={{
                        position: "relative",
                        overflow: "hidden",
                        width: "100%", // Fill the card width
                        height: "200px", // Fixed height for the image container
                        backgroundColor: "#f0f0f0", // Placeholder background
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                    }}
                >
                    <img
                        src={`/images/${product.imageUrls[0]}`}
                        alt={product.title}
                        style={{
                            maxWidth: "100%", // Ensures the image scales down proportionally
                            maxHeight: "100%", // Ensures the image scales to fit the container
                            objectFit: "contain", // Keeps the aspect ratio intact
                        }}
                    />
                </Box>

                {/* Product Details */}
                <CardContent
                    sx={{
                        textAlign: "center",
                        padding: "16px",
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                        justifyContent: "center",
                    }}
                >
                    <Typography
                        variant="h6"
                        sx={{
                            fontWeight: "bold",
                            fontSize: "clamp(1rem, 2vw, 1.5rem)", // Font size adjusts dynamically based on the viewport width
                            marginBottom: 1,
                            whiteSpace: "normal", // Allows text to wrap onto the next line when needed
                            overflow: "hidden", // Prevents overflow outside the container
                            textOverflow: "ellipsis", // Ensures text is truncated with ellipsis if it's too long
                            wordWrap: "break-word", // Breaks long words to fit within the container
                            maxWidth: '100%', // Ensures the element doesn't exceed container width
                            display: 'block', // Makes the element block-level to support wrapping
                        }}
                    >
                        {product.title}
                    </Typography>



                    <Typography
                        variant="h5"
                        color="primary"
                        sx={{
                            color: "#811b08",
                            fontSize: "1.2rem",
                        }}
                    >
                        {product.price + " ₴"}
                    </Typography>
                </CardContent>

                {/* View Button */}
                <Box
                    sx={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        padding: 2,
                    }}
                >
                    <Button
                        variant="contained"
                        sx={{
                            backgroundColor: "rgba(90, 60, 46, 0.8)",
                            color: "#fff",
                            borderRadius: "50px",
                            padding: "10px 20px",
                            fontWeight: "bold",
                            fontSize: "1rem",
                            "&:hover": {
                                backgroundColor: "rgba(90, 60, 46, 1)",
                                transform: "scale(1.05)",
                            },
                        }}
                        onClick={() => handleProductClick(product.id)}
                    >
                        Переглянути
                    </Button>
                </Box>
            </Card>
        </Box>
    );
};

export default ChatProductInfo;
