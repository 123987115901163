import React, {useEffect} from 'react';
import { Box, Grid, Typography, Button } from '@mui/material';
import ProductImage from "../components/productdetails/ProductImage";
import BuyButton from "../components/productdetails/BuyButton";
import DescriptionTabs from "../components/productdetails/DescriptionTabs";
import products from "./data/productData.js";
import { useParams, useNavigate } from "react-router-dom";
import ChatBot from "../components/homepage/chatbot/ChatBot";
import { useNotification } from "../contexts/NotificationContext";
import ArrowBackIcon from '@mui/icons-material/ArrowBack'; // Import ArrowBack Icon


const ProductInfo = () => {

    const { showNotification } = useNotification();

    const { productId } = useParams();
    const navigate = useNavigate(); // Using the history hook for navigating back

    useEffect(() => {
        window.scrollTo(0, 0);  // Scroll to top of the page
    }, []);

    // Handle back button click
    const handleBackClick = () => {
        navigate(-1);
    };

    console.log("Product ID from URL:", productId);
    console.log("Products array:", products);

    const product = products.find((p) => p.id === productId);
    console.log("Matched Product:", product);

    if (!product) {
        return <Typography variant="h5">Product not found</Typography>;
    }

    return (
        <Box sx={{ padding: 2, maxWidth: '1200px', margin: 'auto', zIndex: -1 }}>
            <Grid container spacing={4}>
                {/* Back Button Section */}
                <Grid item xs={12}>
                    <Box sx={{ position: 'absolute', top: 10, left: 20, zIndex: 1000 }}>
                        <Button
                            variant="outlined"
                            startIcon={<ArrowBackIcon />}
                            onClick={handleBackClick}
                        >
                            Назад
                        </Button>
                    </Box>
                </Grid>

                {/* Image and Description Section */}
                <Grid item xs={12}>
                    <Box
                        sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'center',
                        }}
                    >
                        <ProductImage images={product.images} />
                        {/* Buy Section */}
                        <Grid item xs={12} md={6}>
                            <Box
                                display="flex"
                                flexDirection="column" // Stack elements vertically
                                justifyContent="center"
                                alignItems="center"
                                textAlign="center"
                                p={2}
                            >
                                <Typography variant="h4" gutterBottom>
                                    {product.title}
                                </Typography>
                                <Box mt={2}> {/* Add margin to separate the button from the title */}
                                    <BuyButton
                                        price={product.price}
                                        onClick={() =>
                                            showNotification("Увага! Це лише демонстраційний сайт. Перейдіть до списку товарів та спробуйте функцію AI-консультанта! 😊")
                                        }
                                    />
                                </Box>
                            </Box>
                        </Grid>
                        <DescriptionTabs product={product} />
                    </Box>
                </Grid>

                {/* Chatbot Section */}
                <Grid item xs={12} md={3}>
                    <Box sx={{ position: "sticky", top: 100, zIndex: 10000 }}>
                        <ChatBot productId={product.id} />
                    </Box>
                </Grid>
            </Grid>
        </Box>
    );
};

export default ProductInfo;
