import React from "react";
import { Box, Typography, Button } from "@mui/material";
import MainPhoto from "../../../assets/images/MainPhoto.png";


const Banner = ({ onScrollToProducts }) => {
    return (
        <Box
            sx={{
                position: "relative",
                width: "100%",
                height: "100vh", // Full height for the banner
                backgroundImage: `url(${MainPhoto})`,
                backgroundSize: "cover",
                backgroundPosition: "center",
            }}
        >
            {/* Text and Button Container */}
            <Box
                sx={{
                    position: "absolute",
                    top: "50%",
                    left: { xs: "5%", sm: "10%" },
                    transform: "translateY(-50%)",
                    color: "#fff",
                    textAlign: "left",
                }}
            >
                <Typography
                    variant="h2"
                    sx={{
                        fontWeight: "bold",
                        textTransform: "uppercase",
                        letterSpacing: "2px",
                        fontSize: { xs: "2rem", sm: "3rem", md: "4rem" }, // Responsive font sizes
                    }}
                    gutterBottom
                >
                    Осіння Колекція
                </Typography>
                <Button
                    variant="contained"
                    size="large"
                    onClick={onScrollToProducts} // Scrolls down when clicked
                    sx={{
                        backgroundColor: "#d4a373",
                        color: "#fff",
                        padding: { xs: "8px 16px", sm: "10px 20px" }, // Adjust padding for mobile
                        fontSize: { xs: "14px", sm: "16px" },
                        textTransform: "none",
                        "&:hover": {
                            backgroundColor: "#b8845e",
                        },
                    }}
                >
                    Перейти до покупок →
                </Button>
            </Box>
        </Box>
    );
};

export default Banner;
