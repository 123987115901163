import React from 'react';
import { Box, Typography, List, ListItem, Divider } from '@mui/material';

const DescriptionDetails = ({ product }) => {
    const renderListItems = (data) => {
        // Dynamically render each list item
        return Object.entries(data).map(([key, value], index) => {
            if (Array.isArray(value)) {
                return (
                    <ListItem key={index}>
                        {key.charAt(0).toUpperCase() + key.slice(1)}: {value.join(", ")}
                    </ListItem>
                );
            } else if (typeof value === 'object') {
                return (
                    <ListItem key={index}>
                        <Typography variant="body2" fontWeight="bold">{key.charAt(0).toUpperCase() + key.slice(1)}:</Typography>
                        <List>
                            {Object.entries(value).map(([subKey, subValue], subIndex) => (
                                <ListItem key={subIndex}>
                                    {subKey.charAt(0).toUpperCase() + subKey.slice(1)}: {subValue}
                                </ListItem>
                            ))}
                        </List>
                    </ListItem>
                );
            }
            return (
                <ListItem key={index}>
                    {key.charAt(0).toUpperCase() + key.slice(1)}: {value}
                </ListItem>
            );
        });
    };

    return (
        <Box sx={{ padding: 3, backgroundColor: '#f9f9f9', borderRadius: 2 }}>
            {/* Title */}
            <Typography variant="h5" fontWeight="bold" gutterBottom>
                Опис
            </Typography>

            {/* Main Description */}
            <Typography variant="body1" paragraph>
                {product.description.text}
            </Typography>

            <Divider sx={{ marginY: 2 }} />

            {/* Characteristics */}
            <Typography variant="h6" fontWeight="bold" gutterBottom>
                Характеристики:
            </Typography>
            <List>
                {renderListItems(product.description.characteristics)}
            </List>

            {/* Dimensions */}
            <Typography variant="h6" fontWeight="bold" gutterBottom sx={{ marginTop: 2 }}>
                Розміри:
            </Typography>
            <List>
                {renderListItems(product.description.dimensions)}
            </List>

            {/* Features */}
            <Typography variant="h6" fontWeight="bold" gutterBottom sx={{ marginTop: 2 }}>
                Особливості:
            </Typography>
            <List>
                {product.description.features.map((feature, index) => (
                    <ListItem key={index}>{feature}</ListItem>
                ))}
            </List>

            {/* Specifications */}
            <Typography variant="h6" fontWeight="bold" gutterBottom>
                Специфікація:
            </Typography>
            <List>
                {renderListItems(product.description.specification)}
            </List>
        </Box>
    );
};

export default DescriptionDetails;
