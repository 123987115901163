import React, { createContext, useContext } from "react";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const NotificationContext = createContext();

export const useNotification = () => useContext(NotificationContext);

const NotificationProvider = ({ children }) => {
    const showNotification = (message) => {
        toast(message, {
            position: "top-right",
            autoClose: 2000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            theme: "dark",
            style: {
                fontFamily: "'Arial', sans-serif",
                fontSize: "16px",
                fontWeight: "bold",
                color: "#fff",
                backgroundColor: "#333",
                borderRadius: "8px",
                padding: "10px",

            },
        });
    };

    return (
        <NotificationContext.Provider value={{ showNotification }}>
            {children}
            <ToastContainer />
        </NotificationContext.Provider>
    );
};

export default NotificationProvider;
