import {chatbotAxios} from "./Axios";


export const getProductInfo = async (productId) => {
    try {
        return await chatbotAxios({
            url: `https://data.t-market.app/products/${productId}`,
            method: 'GET',
            headers: {
                'Access-Control-Allow-Origin': '*',
                'Content-Type': 'application/json',
            },
            withCredentials: true,
        });
    } catch (error) {
        console.error('Error fetching data:', error);
        throw error;
    }
};
