import React from "react";
import { Box, Grid, Typography } from "@mui/material";

const Footer = () => {
  return (
      <Box
          component="footer"
          sx={{
            backgroundColor: "#4B382A", // Коричневий фон
            color: "#fff", // Білий текст
            padding: { xs: 4, sm: 6, md: 8 },
          }}
      >
        {/* Основний контейнер Grid */}
        <Grid container spacing={4}>
          {/* Розділ Сервісу для клієнтів */}
          <Grid item xs={12} sm={6} md={3}>
            <Typography variant="h6" sx={{ fontWeight: "bold", marginBottom: 2 }}>
              Обслуговування клієнтів
            </Typography>
            <Typography variant="body2" sx={{ marginBottom: 1 }}>Контакти</Typography>
            <Typography variant="body2" sx={{ marginBottom: 1 }}>Умови та положення</Typography>
            <Typography variant="body2" sx={{ marginBottom: 1 }}>Змінити налаштування cookie</Typography>
            <Typography variant="body2" sx={{ marginBottom: 1 }}>Часті запитання</Typography>
            <Typography variant="body2" sx={{ marginBottom: 1 }}>Подарункові карти</Typography>
            <Typography variant="body2" sx={{ marginBottom: 1 }}>Послуги планування</Typography>
            <Typography variant="body2" sx={{ marginBottom: 1 }}>Оренда фургона</Typography>
            <Typography variant="body2">Послуги дизайну інтер'єру</Typography>
          </Grid>

          {/* Розділ Допомоги */}
          <Grid item xs={12} sm={6} md={3}>
            <Typography variant="h6" sx={{ fontWeight: "bold", marginBottom: 2 }}>
              Допомога
            </Typography>
            <Typography variant="body2" sx={{ marginBottom: 1 }}>Відстеження посилки</Typography>
            <Typography variant="body2" sx={{ marginBottom: 1 }}>Політика повернення</Typography>
            <Typography variant="body2" sx={{ marginBottom: 1 }}>Доступність</Typography>
            <Typography variant="body2" sx={{ marginBottom: 1 }}>Доставка</Typography>
            <Typography variant="body2">Політика конфіденційності</Typography>
          </Grid>

          {/* Розділ Про Hombel */}
          <Grid item xs={12} sm={6} md={3}>
            <Typography variant="h6" sx={{ fontWeight: "bold", marginBottom: 2 }}>
              Про Hombel
            </Typography>
            <Typography variant="body2" sx={{ marginBottom: 1 }}>Про нас</Typography>
            <Typography variant="body2" sx={{ marginBottom: 1 }}>Знайти магазин</Typography>
            <Typography variant="body2" sx={{ marginBottom: 1 }}>Години роботи</Typography>
            <Typography variant="body2" sx={{ marginBottom: 1 }}>Сталий розвиток</Typography>
            <Typography variant="body2">Усі меблі</Typography>
          </Grid>

          {/* Розділ Головного офісу */}
          <Grid item xs={12} sm={6} md={3}>
            <Typography variant="h6" sx={{ fontWeight: "bold", marginBottom: 2 }}>
              Головний офіс
            </Typography>
            <Typography variant="body2" sx={{ marginBottom: 1 }}>
              Будівля Hombel, 27D, Космос Сквер, Зеніт Кресент, Інфініті Парк, Дублін, D11 XR79
            </Typography>
            <Typography variant="body2" sx={{ marginBottom: 1 }}>
              Служба підтримки клієнтів: 01 7482938
            </Typography>
            <Typography variant="body2" sx={{ marginBottom: 1 }}>
              Реєстрація компанії: 876452
            </Typography>
            <Typography variant="body2">ПДВ: 4452768PK</Typography>
          </Grid>
        </Grid>

        {/* Соціальні мережі та іконки оплати */}
        <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              marginTop: 4,
              borderTop: "1px solid rgba(255, 255, 255, 0.2)",
              paddingTop: 2,
              flexWrap: "wrap",
            }}
        >
          {/* Іконки соціальних мереж */}
          <Box sx={{ display: "flex", gap: 2 }}>
            <img src="./images/logo/applepay.png" alt="Apple Pay" style={{ height: "24px" }} />
            <img src="./images/logo/visa.png" alt="Visa" style={{ height: "24px" }} />
            <img src="./images/logo/paypal.png" alt="PayPal" style={{ height: "24px" }} />
            <img src="./images/logo/instagram.png" alt="Instagram" style={{ height: "24px" }} />
          </Box>

          {/* Виноска або брендінг */}
          <Typography variant="body2" sx={{ marginTop: { xs: 2, sm: 0 }, textAlign: { xs: "center", sm: "right" } }}>
            © 2024 Hombel. Усі права захищені.
          </Typography>
        </Box>
      </Box>
  );
};

export default Footer;
