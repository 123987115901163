import React, {useEffect, useRef, useState} from "react";
import {ThemeProvider} from "@mui/material/styles";
import theme from "../../../theme";
import aiIcon from "../../../icons/aiicon.png";
import {askQuestion} from "../../../api/ChatBotAPI";
import ChatBotWeb from "../chatbot/ChatBotWeb";
import ChatBotMobile from "../chatbot/ChatBotMobile";
import ChatProductInfo from "./chatproduct/ChatProdcutInfo";
import {getProductInfo} from "../../../api/ProductDataApi";
import Carousel from "react-material-ui-carousel";
import {useNavigate} from "react-router-dom";

const ChatBot = ({productId}) => {
    const navigate = useNavigate();
    const [isChatOpen, setIsChatOpen] = useState(false);
    const [isMobileView, setIsMobileView] = useState(false);
    const [historyMessages, setHistoryMessages] = useState([]);
    const [currentMessages, setMessages] = useState([
        {
            text: "Доброго здоров'я! 👋 Я ваш віртуальний консультант. Як я можу вам допомогти?",
            sender: "bot",
        },
    ]);
    const [input, setInput] = useState("");
    const [isLoading, setIsLoading] = useState(false); // Typing indicator state
    const chatWindowRef = useRef(null);
    const historyLoaded = useRef(false);

    const handleChatOpen = () => {
        setIsChatOpen(true);

        if (!historyLoaded.current) {
            fetchChatHistory();
            historyLoaded.current = true;
        }
    };

    const handleChatClose = () => {
        setIsChatOpen(false);
        historyLoaded.current = false;
    };

    const fetchChatHistory = async () => {
        try {
            const response = await fetch("https://proxy.t-market.app/history", {
                method: "GET",
                credentials: "include",
            });

            if (response.ok) {
                const history = await response.json();

                if (history.length === 0) {
                    setHistoryMessages([]);
                } else {
                    const formattedHistory = await formatHistory(history);
                    console.log(formattedHistory);
                    setHistoryMessages(formattedHistory);
                }
            } else {
                console.error("Failed to fetch chat history:", response.statusText);
                setHistoryMessages([]);
            }
        } catch (error) {
            console.error("Error fetching chat history:", error);
            setHistoryMessages([]);
        }
    };

    const formatHistory = async (history) => {
        const formattedHistory = await Promise.all(
            history.map(async (entry) => {
                let productMessages;
                if (entry.hasOwnProperty("products") && entry.products.length > 0) {
                    const productsData = await Promise.all(
                        entry.products.map(async (productId) => {
                            return (await getProductInfo(productId)).data;
                        })
                    );
                    productMessages = mapProducts(productsData);

                    return [
                        { text: entry.question, sender: "user" },
                        { text: entry.answer, sender: "bot", productMessages: productMessages}
                    ]
                } else {
                    return [
                        { text: entry.question, sender: "user" },
                        { text: entry.answer, sender: "bot"},
                    ];
                }
            })
        );
        return formattedHistory.flat();
    };

    const handleProductClick = (productId) => {
        if (isMobileView) {
            setIsChatOpen(false);
        }
        navigate(`/product/${productId}`);
    };

    useEffect(() => {
        if (chatWindowRef.current) {
            chatWindowRef.current.scrollTop = chatWindowRef.current.scrollHeight;
        }
    }, [historyMessages, currentMessages]);

    const handleResize = () => {
        setIsMobileView(window.innerWidth < 600);
    };

    useEffect(() => {
        handleResize();
        window.addEventListener("resize", handleResize);
        return () => {
            window.removeEventListener("resize", handleResize);
        };
    }, []);

    useEffect(() => {
        document.body.style.overflow = isChatOpen && isMobileView ? "hidden" : "";
        return () => {
            document.body.style.overflow = "";
        };
    }, [isChatOpen, isMobileView]);

    const cleanText = (text) => {
        const urlPattern = /(https?:\/\/[^\s.]+(?:\.[^\s.]+)+)/g;
        return text.split(urlPattern).map((part, index) =>
            part.match(urlPattern) ? (
                <a key={index} href={part} target="_blank" rel="noopener noreferrer">
                    {part}
                </a>
            ) : (
                part
            )
        );
    };

    const mapProducts = (productsData) => {
        return (
            <Carousel
                autoPlay={false}
                infiniteLoop={true}
                showThumbs={false}
                showStatus={false}
                emulateTouch={true}
                swipeable={true}
            >
                {productsData.map((product) => (
                    <div
                        key={product.id}
                        style={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            height: "100%", // Ensures it fills the carousel height
                        }}
                    >
                        <div
                            style={{
                                width: "100%", // Make it take the full width of the carousel
                                height: "100%", // Match the height of the carousel container
                                boxSizing: "border-box", // Ensure padding and borders don't affect the size
                                padding: "10px" // Add optional padding if needed
                            }}
                        >
                            <ChatProductInfo
                                product={product}
                                handleProductClick={handleProductClick}
                                style={{
                                    width: "100%", // Ensure the component also fills its container
                                    height: "100%", // Match the height as well
                                }}
                            />
                        </div>
                    </div>
                ))}
            </Carousel>
        );
    }

    const handleSendMessage = async () => {
        if (input.trim() === "") return;

        const userMessage = {text: input, sender: "user"};
        setMessages((prevMessages) => [...prevMessages, userMessage]);
        setInput("");
        setIsLoading(true);

        try {
            const response = await askQuestion({productId, question: input});

            if (response.data.products && response.data.products.length > 0) {
                console.log(123123)
                const productsData = await Promise.all(
                    response.data.products.map(async (productId) => {
                        return (await getProductInfo(productId)).data;
                    })
                );
                console.log(productsData);
                // Map over the fetched product data and generate product messages
                const productMessages = mapProducts(productsData);


                // Add product info and the answer message to the chat
                setMessages((prevMessages) => [
                    ...prevMessages,
                    {
                        text: cleanText(response.data.answer) || "Here are some products you might like:",
                        sender: "bot",
                        productMessages: productMessages, // Add product components
                    },
                ]);
            } else {
                setMessages((prevMessages) => [
                    ...prevMessages,
                    {
                        text: cleanText(response.data.answer) || "Sorry, I couldn't get an answer at this time.",
                        sender: "bot",
                    },
                ]);
            }
        } catch (error) {
            setMessages((prevMessages) => [
                ...prevMessages,
                {
                    text: "Вибачте, сталась халепа, зачекайте, будь-ласка, поки ми вирішимо технічні проблеми",
                    sender: "bot",
                },
            ]);
        } finally {
            setInput("")
            setIsLoading(false);
        }
    };

    const chunkProducts = (products, chunkSize) => {
        const chunks = [];
        for (let i = 0; i < products.length; i += chunkSize) {
            chunks.push(products.slice(i, i + chunkSize)); // Create chunks of size chunkSize
        }
        return chunks;
    };

    return (
        <ThemeProvider theme={theme}>
            <div>
                {/* Chatbot Icon */}
                {!isChatOpen && (
                    <div className="chatbot-chat-icon" onClick={handleChatOpen}>
                        <img src={aiIcon} alt="T-market Bot" className="chatbot-bot-icon"/>
                    </div>
                )}

                {/* Chatbot Component */}
                {isChatOpen && (
                    isMobileView ? (
                        <ChatBotMobile
                            historyMessages={historyMessages}
                            currentMessages={currentMessages}
                            setInput={setInput}
                            input={input}
                            handleSendMessage={handleSendMessage}
                            chatWindowRef={chatWindowRef}
                            onClose={handleChatClose}
                            isLoading={isLoading} // Pass isLoading prop
                        />
                    ) : (
                        <ChatBotWeb
                            historyMessages={historyMessages}
                            currentMessages={currentMessages}
                            setInput={setInput}
                            input={input}
                            handleSendMessage={handleSendMessage}
                            chatWindowRef={chatWindowRef}
                            onClose={handleChatClose}
                            isLoading={isLoading} // Pass isLoading prop
                        />
                    )
                )}

            </div>
        </ThemeProvider>
    );
};

export default ChatBot;
