import React, {useRef} from "react";
import { Box, Grid, Typography, Button, Card, CardContent, CardMedia } from "@mui/material";
import { useNavigate } from "react-router-dom";
import Header from "../components/homepage/header/Header";
import Banner from "../components/homepage/header/Banner";
import Footer from "../components/homepage/footer/Footer";
import MidSection from "../components/homepage/midSection/MidSection";
import Carousel from "react-material-ui-carousel";
import products from "./data/productData.js";

const Homepage = () => {
    const navigate = useNavigate();
    const productsRef = useRef(null);

    const handleScrollToProducts = () => {
        productsRef.current.scrollIntoView({ behavior: "smooth" });
    };

    const handleProductClick = (productId) => {
        navigate(`/product/${productId}`);
    };

    return (
        <Box>
            {/* Header */}
            <Header />

            {/* Banner */}
            <Banner onScrollToProducts={handleScrollToProducts} />

            {/* Stylish Boundary */}
            <Box
                sx={{
                    height: "4px",
                    width: "100%",
                    background: "linear-gradient(90deg, #d4a373, #f9f9f9)", // Stylish gradient
                    marginY: 4,
                }}
            />

            {/* Mid Section */}
            <MidSection />

            {/* Stylish Boundary */}
            <Box
                sx={{
                    height: "4px",
                    width: "100%",
                    background: "linear-gradient(90deg, #d4a373, #f9f9f9)", // Stylish gradient
                    marginY: 4,
                }}
            />

            {/* Products Section */}
            <Box ref={productsRef} sx={{ padding: { xs: 2, sm: 4 } }}>
                <Typography
                    variant="h4"
                    sx={{
                        fontWeight: "bold",
                        mb: 4,
                        textAlign: "center",
                        fontSize: { xs: "1.5rem", sm: "2rem" },
                    }}
                >
                    Новинки
                </Typography>
                <Grid container spacing={{ xs: 2, sm: 3, md: 4 }}>
                    {products.map((product) => (
                        <Grid item xs={12} sm={6} md={4} key={product.id}>
                            <Card sx={{ display: "flex", flexDirection: "column", height: "100%" }}>
                                {/* Image Carousel */}
                                <Box sx={{ position: "relative", overflow: "hidden" }}>
                                    <Carousel
                                        indicators={true}
                                        navButtonsAlwaysVisible
                                        autoPlay={false}
                                        sx={{
                                            height: { xs: "200px", sm: "300px", md: "350px" }, // Responsive heights
                                            overflow: "hidden", // Ensure no overflow from children
                                        }}
                                    >
                                        {product.images.map((image, index) => (
                                            <CardMedia
                                                key={index}
                                                component="img"
                                                image={image}
                                                alt={`${product.title} - ${index + 1}`}
                                                sx={{
                                                    objectFit: "contain", // Ensures the whole image fits within the box
                                                    width: "100%",       // Maintain full width
                                                    height: { xs: "200px", sm: "300px", md: "350px" }, // Match parent height
                                                }}
                                            />
                                        ))}
                                    </Carousel>


                                </Box>
                                <CardContent sx={{ flexGrow: 1, textAlign: "center" }}>
                                    <Typography
                                        variant="h6"
                                        sx={{
                                            fontWeight: "bold",
                                            fontSize: { xs: "1rem", sm: "1.25rem" },
                                        }}
                                    >
                                        {product.title}
                                    </Typography>
                                    <Typography
                                        variant="body2"
                                        color="text.secondary"
                                        sx={{
                                            mb: 2,
                                            fontSize: { xs: "0.8rem", sm: "1rem" },
                                        }}
                                    >
                                        {product.description_short}
                                    </Typography>
                                    <Typography
                                        variant="h5"
                                        color="primary"
                                        sx={{
                                            color: "#811b08",
                                            fontSize: { xs: "1.2rem", sm: "1.5rem" },
                                        }}
                                    >
                                        {product.price}
                                    </Typography>
                                </CardContent>
                                <Button
                                    variant="contained"
                                    sx={{
                                        backgroundColor: "rgba(90, 60, 46, 0.8)",
                                        color: "#fff",
                                        borderRadius: "50px",
                                        padding: { xs: "8px 20px", sm: "10px 30px" },
                                        fontWeight: "bold",
                                        m: 2,
                                        fontSize: { xs: "0.8rem", sm: "1rem" },
                                        "&:hover": {
                                            backgroundColor: "rgba(90, 60, 46, 1)",
                                            transform: "scale(1.05)",
                                        },
                                    }}
                                    onClick={() => handleProductClick(product.id)}
                                >
                                    Переглянути
                                </Button>
                            </Card>
                        </Grid>
                    ))}
                </Grid>
            </Box>

            {/* Footer */}
            <Footer />
        </Box>
    );
};

export default Homepage;
